// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dokumenty-js": () => import("./../../../src/pages/dokumenty.js" /* webpackChunkName: "component---src-pages-dokumenty-js" */),
  "component---src-pages-edukacja-js": () => import("./../../../src/pages/edukacja.js" /* webpackChunkName: "component---src-pages-edukacja-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-kinie-js": () => import("./../../../src/pages/o-kinie.js" /* webpackChunkName: "component---src-pages-o-kinie-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-document-template-js": () => import("./../../../src/templates/document-template.js" /* webpackChunkName: "component---src-templates-document-template-js" */),
  "component---src-templates-education-template-js": () => import("./../../../src/templates/education-template.js" /* webpackChunkName: "component---src-templates-education-template-js" */),
  "component---src-templates-movie-template-js": () => import("./../../../src/templates/movie-template.js" /* webpackChunkName: "component---src-templates-movie-template-js" */)
}

