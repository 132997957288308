module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kino Grażyna Strzelin","short_name":"Kino Grażyna","start_url":"/","background_color":"#f9f9f9","theme_color":"#303f9f","display":"minimal-ui","icon":"src/images/logo-kino-grazyna-z-tlem.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c6d2cde85f8e2a481d44ae79b47baa8f"},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
