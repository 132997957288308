import 'typeface-open-sans'
import './src/styles-global/bootstrap-custom.scss'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
